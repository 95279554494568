/* @import "./bootstrap.min.css"; */
/* @import "./themify-icons.css"; */
/* @import "./core.css"; */
/* @import "./hamburgers.css"; */
/* @import "./font-awesome.min.css"; */
@import "./custom-css.css";
@import "./toggle-switch.css";

.accordion-header button {
  width: 100% !important;
  text-align: left !important;
  padding: 10px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #e8eef3 !important;
}

a {
  text-decoration: none !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (max-width: 991.98px) {
  .main-sidebar {
    transform: translate3d(-293px, 0, 0) !important;
  }

  .main-sidebar-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    backdrop-filter: blur(2px);
  }

  .main-sidebar.open {
    transform: translate3d(0, 0, 0) !important;
  }
}

.bg-orange {
  background: #f69b31 !important;
}
.react-loader-spinner > div {
  width: fit-content;
  margin: auto;
}

.root {
  height: 100vh;
  overflow: hidden;
}
.full-height-cont {
  /* height: 100vh; */
  /* padding: 20px 0; */
  /* overflow: hidden; */
}
.centered-container {
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: scroll; */
}

/* .main-container .content-area {
  padding-top: 100px;
} */
.form-control {
  padding: 5px 10px !important;
}
.phone-input {
  display: flex !important;
}
.phone-input input {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  margin-left: 36px !important;
  padding: 10px !important;
  font-size: 16px;
}
.phone-input input:focus {
  width: 100% !important;
  border: none !important;
  outline: none !important;
  box-shadow: inset 0 -1px 0 #ddd !important;
}
.otp-input {
  width: 40px !important;
  height: 40px !important;
  max-width: 15%;
  /* border-radius: 10px; */
  /* font-weight: 600; */
  margin: 0 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.fullscreen-error-overlay {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
}

.fullscreen-error-overlay .error-container {
  width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 80%;
  border-radius: 10px;
  background-color: rgb(77, 76, 76);
  color: white;
}
.fullscreen-error-overlay .error-container p {
  margin: 0;
  text-align: center;
  text-justify: center;
}
.cursor-pointer {
  cursor: pointer !important;
}

.sticky-progress-stepper {
  position: sticky;
  top: 0;
  z-index: 1030;
  background: white;
  padding-bottom: 20px;
  /* border-bottom: 2px solid black; */
}
.verify-doc-image {
  height: 150px;
  object-fit: contain;
}

.gm-style-iw-t button {
  display: none !important;
}
/* .gm-style .gm-style-iw-tc {
  display: none !important;
} */
/* .gm-style-iw-d {
  display: none !important;
} */

.dropdown-sidebar {
  height: 0;
  overflow: hidden;
  transition: all;
  /* transition-duration: 5s; */
}

.dropdown-sidebar.show {
  height: 100%;
}
.main-sidebar .parent-nav.open {
  background: #111827;
}
.bg-brand {
  background-color: #00a699 !important;
}
.text-brand {
  color: #00a699 !important;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100vw;
  height: 30px;
  background-color: black;
  color: white;
  position: relative;
}

.marquee {
  display: inline-block;
  width: 100vw;
  animation: marqueeAnimation 20s linear infinite;
  /* background-color: gray; */
  position: absolute;
  height: 30px;
}
.marquee span {
  /* background-color: green; */
}

/* @keyframes marqueeAnimation {
  0%,
  100% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(0%);
  }
} */

@keyframes marqueeAnimation {
  0% {
    /* transform: translateX(100%); */
    /* left: 100%; */
    margin-left: 100%;
  }
  50% {
    /* transform: translateX(100%); */
    /* left: 50%; */
    margin-left: 50%;
  }
  100% {
    /* transform: translateX(-100%); */
    /* left: 0%; */
    margin-left: -18%;
  }
}
