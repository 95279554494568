/* ToggleSwitch.css */
.toggle-switch {
  display: flex;
  align-items: center;
}

.switch-cont {
  position: relative;
  display: inline-block;
  width: 50px;
  /* height: 30px !important; */
  margin-right: 30px;
  margin-left: 10px;
  margin-bottom: 0;
}

.switch-cont input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  width: 100%;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 5px;
  top: 6px;
  bottom: 6px;
  /* top: 50%;
  transform: translateY(-50%); */
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #21f333;
}

input:focus + .slider {
  box-shadow: 0 0 1px #21f333;
}

input:checked + .slider:before {
  /* -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px); */
  transform: translate(26px);
}

.slider.round {
  border-radius: 34px;
  height: 27px;
}

.slider.round:before {
  border-radius: 50%;
}

.status {
  font-size: 20px;
  margin: 0;
  /* font-weight: 600; */
}

.on {
  color: #21f333;
}

.off {
  color: #7c7c7c;
}
